'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import Script from 'next/script';
import { useEffect } from 'react';
import { useAppDispatch } from '@akinon/next/redux/hooks';
import { resetHeaderState } from '@akinon/next/redux/reducers/header';
import { closeMiniBasket } from '@akinon/next/redux/reducers/root';
import { ROUTES } from 'routes';

export default function RootTemplate({
  children
}: {
  children: React.ReactNode;
}) {
  const dispatch = useAppDispatch();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const URL = process.env.NEXT_PUBLIC_URL;
  const currentUrl = URL + pathname + searchParams;

  useEffect(() => {
    dispatch(closeMiniBasket());
    dispatch(resetHeaderState());
  }, [dispatch, pathname, searchParams]);

  useEffect(() => {
    if (pathname === ROUTES.HOME) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  const jsonLdWebSite = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: currentUrl,
    name: 'Project Zero',
    potentialAction: [
      {
        '@type': 'SearchAction',
        target: `${URL}/list/?search_text={search_term_string}`,
        'query-input': 'required name=search_term_string'
      }
    ]
  };

  const jsonLdOrganization = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: currentUrl,
    name: 'Project Zero',
    logo: `${URL}/logo.svg`
  };

  return (
    <>
      {/* Google Tag Manager */}
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${
              process.env.NEXT_PUBLIC_GTM_KEY || 'GTM-5CPFN92P'
            }');`
        }}
      />
      {/* End Google Tag Manager */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdWebSite) }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdOrganization) }}
      />
      {children}
    </>
  );
}
